<template>
	<div>
		<router-link class="nav-link col-3" active-class="active" :to="{ name: 'tiersQualifications'}" href="#">
			<h2>{{ $t('qualification.qualifications') }}</h2>
       	</router-link>
		<ShutterPanel />
	</div>
</template>

<script>
import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
import Shutter from '@/mixins/Shutter'
import QualifShutters from '@/mixins/shutters-manager/Qualif'

export default {
	name: 'QualificationFiche',
	mixins: [Shutter, QualifShutters],
	props: {
		/**
		 * horse ou tiers
		 */
		qualificationlink_type: String,
		/**
		 * La FK vers un tiers ou un horse
		 */
		qualificationlink_fk: [String, Number],
		dashboard :{
			default: false,
			type: Boolean,
		}
	},
	mounted() {
		this.init_component()
	},
	methods: {
		init_component() {
			let shutter = this.qualifShutters['qualification-fiche-liste']
			shutter.props.qualificationlink_type = this.qualificationlink_type
			shutter.props.qualificationlink_fk = this.qualificationlink_fk
			shutter.props.dashboard = this.dashboard
			this.shutterPanel().open(shutter)
		}
	},
	components: {
		ShutterPanel
	}
}
</script>
