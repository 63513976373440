export default {
	data: function() {
		return {
			qualifShutters: {
				'qualification-fiche-liste': {
					name: 'qualification-fiche-liste',
					title: this.$t('qualification.qualifications'),
					cmpPath: 'components/Qualification/QualificationFicheListe',
					props: {
						qualificationlink_type: null,
						qualificationlink_fk: null
					}
				},
				'qualification-fiche-edit': {
					name: 'qualification-fiche-edit',
					title: this.$t('qualification.ajouter_qualification'),
					cmpPath: 'components/Qualification/QualificationFicheEditor',
					props: {
						qualificationtype_libelle: null,
						qualificationlink_type: null,
						qualificationlink_fk: null
					}
				}
			}
		}
	},
}
